<template>
  <header>
    <div class="header-fixed">
      <nav
        class="navbar navbar-expand-lg header-nav scroll-sticky"
        v-bind:class="$route.meta.NavbarClass"
        :class="{ 'add-header-bg': isSticky }"
      >
        <div class="container">
          <div class="navbar-header">
            <a @click="toggleSidebar" id="mobile_btn" href="javascript:void(0)">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <router-link
              to="/home/"
              class="navbar-brand logo"
              v-if="isHomethreeRoute"
            >
              <img
                src="@/assets/img/logo/logo.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link>
            <router-link
              to="/home/"
              class="navbar-brand logo"
              v-else-if="isHomefourRoute"
            >
              <img
                src="@/assets/img/logo/logo-five.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link>
            <router-link to="/home/" class="navbar-brand logo" v-else>
              <img
                src="@/assets/img/logo/logo_cabecera.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link>
          </div>
          <div class="main-menu-wrapper">
            <div class="menu-header">
              <router-link to="/home" class="menu-logo">
                <img
                  src="@/assets/img/logo/logo_papo2.svg"
                  class="img-fluid"
                  alt="Logo"
                />
              </router-link>
              <a
                @click="closeSidebar"
                class="menu-close"
                href="javascript:void(0)"
              >
                <i class="fas fa-times" style="color: #fff"></i>
              </a>
            </div>
            <ul class="main-nav">
              <header-menu></header-menu>
              <router-link to="/" class="Login" v-if="!auth.user">
                <i class="fas fa-user mx-2"></i>
                <span>Iniciar Sesion</span>
              </router-link>
            </ul>
          </div>
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
</template>

<script>
import { useAuth } from "@/stores"; // Importar el store de autenticación
export default {
  data() {
    return {
      routes: {
        home: "/home/",
        login: "/",
        register: "/register",
      },
      isSticky: false,
      isSidebarOpen: true,
      auth: useAuth(), // Instancia del store de autenticación
    };
  },
  computed: {
    isHomethreeRoute() {
      return this.$route.path === "/index-three";
    },
    isHomefourRoute() {
      return this.$route.path === "/index-four";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
<style>
.Login {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #f7f1ee;
  line-height: 70px;
  padding: 0 !important;
}
.menu-header {
  background-color: #14123b !important;
}
.menu-logo {
  background-color: #14123b;
}
@media (max-width: 991px) {
  .main-menu-wrapper {
    background-color: #14123b !important;
  }
}
</style>
