<template>
  <template v-for="item in filteredHeaderData" :key="item.tittle">
    <li 
      v-if="item.tittle === 'Estudiante' && auth.user?.tipo_usuario === 4"
      :class="{ active: route_array[1] === item.active_link }"
    >
      <router-link to="/student/student-settings">{{ item.tittle }}</router-link>
    </li>
    <li 
      v-else-if="item.tittle === 'Cursos' && auth.user?.tipo_usuario === 4"
      :class="{ active: route_array[1] === item.active_link }"
    >
      <router-link to="/course/course-grid">{{ item.tittle }}</router-link>
    </li>
    <li
      v-else-if="item.separateRoute"
      :class="{ active: route_array[1] === item.active_link }"
    >
      <router-link :to="{ path: item.route }">{{ item.tittle }}</router-link>
    </li>
    <li
      v-else
      class="has-submenu"
      :class="{
        active: route_array[1] === (item.active_link ? item.active_link : ''),
      }"
    >
      <a href="javascript:void(0);" @click="toggleTab(item)">
        {{ item.tittle }} <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="submenu" :class="{ 'd-block': item.showAsTab }">
        <template
          v-for="menuItem in item.menu"
          :key="menuItem.menuValue"
        >
          <li
            v-if="!menuItem.hasSubRoute"
            :class="{
              active:
                currentPath ===
                (menuItem.active_link ? menuItem.active_link : ''),
            }"
          >
            <router-link :to="{ path: menuItem.route }">{{
              menuItem.menuValue
            }}</router-link>
          </li>
          <li
            v-else
            class="has-submenu"
            :class="{
              active:
                route_array[2] ===
                (menuItem.active_link ? menuItem.active_link : ''),
            }"
          >
            <a href="javascript:void(0);">{{ menuItem.menuValue }}</a>
            <ul class="submenu" :class="{ 'd-block': menuItem.showSubRoute }">
              <template
                v-for="subMenu in menuItem.subMenus"
                :key="subMenu.menuValue"
              >
                <li
                  :class="{
                    active:
                      currentPath ===
                      (subMenu.active_link ? subMenu.active_link : ''),
                  }"
                >
                  <router-link :to="{ path: subMenu.route }">{{
                    subMenu.menuValue
                  }}</router-link>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </li>
  </template>
</template>

<script>
import { useAuth } from "@/stores"; // Importar el store de autenticación
import HeaderData from "@/assets/json/header.json";

export default {
  data() {
    return {
      HeaderData: HeaderData, // Datos del menú
      route_array: [], // Partes de la ruta actual
      auth: useAuth(), // Instancia del store de autenticación
    };
  },
  computed: {
    currentPath() {
      this.route_array = this.$route.path.split("/");
      return this.$route.path;
    },
    userPermissions() {
      // Convertir el objeto de permisos a un array de nombres de permisos
      return this.auth.user?.permissions
        ? Object.keys(this.auth.user.permissions) // Obtener las claves de los permisos
        : [];
    },
    filteredHeaderData() {
      // Filtrar los elementos del menú principal
      console.log(require("@/assets/json/header-public.json"));
      if (!this.auth.user) return require("@/assets/json/header-public.json");
      return this.HeaderData.filter((item) => {
        if (!item.permissions) return true; // Si no requiere permisos, mostrarlo
        return this.hasPermission(item.permissions);
      }).map((item) => ({
        ...item,
        menu: item.menu
          ? item.menu
              .filter((menuItem) => {
                if (!menuItem.permissions) return true; // Si no requiere permisos, mostrarlo
                return this.hasPermission(menuItem.permissions);
              })
              .map((menuItem) => ({
                ...menuItem,
                subMenus: menuItem.subMenus
                  ? menuItem.subMenus.filter((subMenu) => {
                      if (!subMenu.permissions) return true; // Si no requiere permisos, mostrarlo
                      return this.hasPermission(subMenu.permissions);
                    })
                  : [],
              }))
          : [],
      }));
    },
  },
  methods: {
    toggleTab(item) {
      item.showAsTab = !item.showAsTab;
    },
    hasPermission(requiredPermissions) {
      // Verificar si el usuario tiene al menos uno de los permisos requeridos
      return requiredPermissions.some((permission) =>
        this.userPermissions.includes(permission)
      );
    },
  },
};
</script>
