<template>
  <footer class="footer">
    <div class="footer-line"></div>
    <div class="footer-container">
      <div class="footer-section">
        <img
          src="@/assets/img/logo_pie.png"
          alt="Logo"
          class="footer-logo"
        />
        <p class="footer-description">
          Estudia programación y desarrollo de software de manera diferente.
          Aprende, comparte y crece con nosotros.
        </p>
      </div>

      <div class="footer-section">
        <h3 class="footer-title">Enlaces Rápidos</h3>
        <ul class="footer-links">
          <li>
            <router-link to="/"><i class="fas fa-home"></i> Inicio</router-link>
          </li>
          <li>
            <router-link to="/course/course-grid"
              ><i class="fas fa-book"></i> Cursos</router-link
            >
          </li>
          <li>
            <router-link to="/acercade/quienessomos"
              ><i class="fas fa-users"></i> Quiénes Somos</router-link
            >
          </li>
          <li>
            <router-link to="/register"
              ><i class="fas fa-user-plus"></i> Registrarse</router-link
            >
          </li>
        </ul>
      </div>

      <div class="footer-section">
        <h3 class="footer-title">Síguenos</h3>
        <div class="footer-socials">
          <a href="https://www.facebook.com/profile.php?id=61559264905781" target="_blank" class="social-link">
            <i class="fab fa-facebook"></i> Facebook
          </a>
          <a href="https://www.instagram.com/paponetwork/" target="_blank" class="social-link">
            <i class="fab fa-instagram"></i> Instagram
          </a>
          <a href="#" target="_blank" class="social-link">
            <i class="fab fa-youtube"></i> YouTube
          </a>
          <a href="#" target="_blank" class="social-link">
            <i class="fab fa-twitter"></i> Twitter
          </a>
        </div>
      </div>

      <div class="footer-section">
        <h3 class="footer-title">Contáctanos</h3>
        <p class="footer-contact">
          <a href="mailto:contacto@paponetwork.com" class="social-link">
            <i class="fas fa-envelope"></i> Correo Electrónico
          </a>
        </p>
        <p class="footer-contact">
          <a
            href="https://wa.me/59169149458"
            target="_blank"
            class="social-link"
          >
            <i class="fab fa-whatsapp whatsapp-icon"></i> +591 69149458
          </a>
        </p>
      </div>
    </div>

    <div class="footer-bottom">
      <p>© 2024 PapoNetwork. Todos los derechos reservados.</p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #1d1d2b, #171723);
  color: #f7f1ee;
  padding: 40px 20px;
  font-family: "Astronomus";
}

/* Fondo animado con puntos */
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#ffffff 1px, transparent 1px);
  background-size: 10px 10px;
  animation: move-dots 20s linear infinite;
  z-index: 0;
  opacity: 0.2;
}

.footer-line {
  height: 6px;
  background-color: #73cbd6;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(115, 203, 214, 0.7);
}

.footer-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.footer-logo {
  width: 200px;
  margin-bottom: 15px;
  text-align: center;
}

.footer-description {
  font-size: 14px;
  line-height: 1.5;
  font-family: "Astronomus";
  text-align: center;
}

.footer-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #73cbd6;
  font-weight: bold;
  border: 2px solid #73cbd6;
  padding: 5px 10px;
  display: inline-block;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  text-decoration: none;
  color: #f7f1ee;
  font-size: 14px;
}

.footer-links a:hover {
  color: #73cbd6;
}

.footer-links i {
  margin-right: 8px;
}

.footer-socials {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.social-link {
  color: #f7f1ee;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.social-link i {
  margin-right: 8px;
  font-size: 18px;
}

.social-link:hover {
  color: #8475b6;
}

.footer-contact {
  margin: 8px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-bottom {
  border-top: 1px solid #233269;
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #f7f1ee;
}
.whatsapp-link {
  display: flex;
  align-items: center;
  color: #f7f1ee;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.whatsapp-link:hover {
  color: #73cbd6;
}

.whatsapp-icon {
  font-size: 20px;
}
/* Animación del fondo */
@keyframes move-dots {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 100px;
  }
}
</style>
