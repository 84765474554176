<template>
  <div class="row">
    <login-banner></login-banner>

    <div class="col-md-6 login-wrap-bg">
      <!-- Login -->
      <div class="login-wrapper">
        <div class="loginbox">
          <div class="img-logo">
            <img
              src="@/assets/img/logo/logo_cabecera.png"
              class="img-fluid"
              alt="Logo"
            />
            <div class="back-home">
              <router-link to="/home/">Volver a Inicio</router-link>
            </div>
          </div>
          <h1>Inscribirse</h1>
          <Form
            class="register"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <span style="color: #685f89">Nombres</span>
              <Field
                name="nombre"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.nombre }"
                v-model="nombre"
              />
              <div class="invalid-feedback">{{ errors.nombre }}</div>
              <div class="text-danger" id="nombre"></div>
            </div>
            <div class="form-group mt-3">
              <span style="color: #685f89">Apellidos</span>
              <Field
                name="apellidos"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.apellidos }"
                v-model="apellidos"
              />
              <div class="invalid-feedback">{{ errors.apellidos }}</div>
              <div class="text-danger" id="apellidos"></div>
            </div>
            <div class="form-group mt-3">
              <span style="color: #685f89">Correo electrónico</span>
              <Field
                name="email"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
                v-model="email"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
              <div class="emailshow text-danger" id="email"></div>
            </div>
            <div class="form-group mt-3">
              <span style="color: #685f89">Contraseña</span>
              <div class="pass-group" id="passwordInput">
                <Field
                  name="password"
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': errors.password }"
                  v-model="password"
                />
                <span @click="toggleShow" class="toggle-password">
                  <i
                    :class="{
                      'fas fa-eye': showPassword,
                      'fas fa-eye-slash': !showPassword,
                    }"
                  ></i>
                </span>
                <div class="invalid-feedback">{{ errors.password }}</div>
                <div class="emailshow text-danger" id="password"></div>
              </div>
            </div>
            <div class="form-group mt-3">
              <span style="color: #685f89">País</span>
              <vue-select
                v-model="select_pais"
                name="pais"
                :options="paises"
                :class="{ 'is-invalid': errors.pais }"
              />
            </div>
            <div class="invalid-feedback">{{ errors.pais }}</div>
            <div class="text-danger" id="pais"></div>
            <div class="form-check remember-me mt-2" v-if="false">
              <label class="form-check-label mb-0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="remember"
                />
                I estar de acuerdo con el
                <router-link to="/pages/term-condition"
                  >Términos de servicio</router-link
                >
                y
                <router-link to="/pages/privacy-policy"
                  >política de privacidad.</router-link
                >
              </label>
            </div>
            <div class="d-grid mt-3">
              <button
                class="btn btn-primary btn-start"
                type="submit"
                @click="registrarEstudiante($event)"
              >
                Crear una cuenta
              </button>
            </div>
          </Form>
        </div>
        <div class="google-bg text-center">
          <span v-if="false"
            ><a href="javascript:void(0);">O inicia sesión con</a></span
          >
          <div class="sign-google" v-if="false">
            <ul>
              <li>
                <a href="javascript:void(0);"
                  ><img
                    src="@/assets/img/net-icon-01.png"
                    class="img-fluid"
                    alt="Logo"
                  />
                  Iniciar sesión usando Google</a
                >
              </li>
              <li>
                <a href="javascript:void(0);"
                  ><img
                    src="@/assets/img/net-icon-02.png"
                    class="img-fluid"
                    alt="Logo"
                  />Iniciar sesión usando Facebook</a
                >
              </li>
            </ul>
          </div>
          <p class="mb-0">
            ¿Ya tienes una cuenta?
            <router-link to="/">Iniciar sesión</router-link>
          </p>
        </div>
      </div>
      <!-- /Login -->
    </div>
  </div>
</template>
<script>
import { Form, Field, FieldArray } from "vee-validate";
import { router } from "@/router";
import VueRouter from "vue-router";
import * as Yup from "yup";

import EstudianteService from "@/service/EstudianteService";

export default {
  data() {
    return {
      password: "",
      email: "",
      nombre: "",
      apellidos: "",
      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {},
      rolModal: false,
      paises: [],
      select_pais: null,
    };
  },
  components: {
    Form,
    Field,
  },
  auth: null,
  created() {
    this.estudianteService = new EstudianteService();
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "esconder" : "Espectáculo";
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Se requiere correo electrónico")
        .email("El correo electrónico no es válido."),
      password: Yup.string()
        .required("Se requiere contraseña")
        .min(8, "La contraseña debe tener al menos 8 caracteres"),
      nombre: Yup.string().required("El nombre es requerido").min(3),
      apellidos: Yup.string().required("El apellido es requerido").min(3),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.obetenerdatosNecesarios();
  },
  methods: {
    handlePasswordChange() {
      let passwordValue = this.password;
      let passwordLength = passwordValue.length;
      let poorPassword = /[a-z]/.test(passwordValue);
      let weakPassword = /(?=.*?[0-9])/.test(passwordValue);
      let strongPassword = /(?=.*?[#?!@$%^&*-])/.test(passwordValue);
      let whitespace = /^\s*$/.test(passwordValue);

      if (passwordValue !== "") {
        if (whitespace) {
          this.errors.password = "whitespaces are not allowed";
        } else {
          this.errors.password = "";
          this.poorPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.weakPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.strongPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.heavyPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
        }
      } else {
        this.errors.password = "";
        this.validationError = 0;
        this.strength = "";
      }
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    poorPasswordStrength(
      passwordLength,
      poorPassword,
      weakPassword,
      strongPassword
    ) {
      if (passwordLength < 8) {
        this.validationError = 2;
        this.strength = "poor";
      }
    },

    weakPasswordStrength(
      passwordLength,
      poorPassword,
      weakPassword,
      strongPassword
    ) {
      if (
        passwordLength >= 8 &&
        (poorPassword || weakPassword || strongPassword)
      ) {
        this.validationError = 3;
        this.strength = "weak";
      }
    },

    strongPasswordStrength(
      passwordLength,
      poorPassword,
      weakPassword,
      strongPassword
    ) {
      if (
        passwordLength >= 8 &&
        poorPassword &&
        (weakPassword || strongPassword)
      ) {
        this.validationError = 4;
        this.strength = "strong";
      }
    },

    heavyPasswordStrength(
      passwordLength,
      poorPassword,
      weakPassword,
      strongPassword
    ) {
      if (
        passwordLength >= 8 &&
        poorPassword &&
        weakPassword &&
        strongPassword
      ) {
        this.validationError = 5;
        this.strength = "heavy";
      }
    },
    obetenerdatosNecesarios() {
      this.estudianteService.datosNecesarios().then((response) => {
        this.paises = response.paises;
        for (let i = 0; i < this.paises.length; i++) {
          this.paises[i].value = this.paises[i].id;
          this.paises[i].text = this.paises[i].nombre;
        }
      });
    },
    registrarEstudiante(event) {
      event.preventDefault();

      let data_enviar = {
        nombres: this.nombre,
        apellidos: this.apellidos,
        email: this.email,
        password: this.password,
        pais_id: this.select_pais,
      };
      console.log(data_enviar);
      this.estudianteService.guardarEstudiante(data_enviar).then((response) => {
        if (response.errors) {
          console.log(response.errors);
        } else {
          this.$router.push({ name: "login" });
        }
      });
    },
  },
};
</script>
