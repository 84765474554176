<template>
  <!-- Header -->
  <header class="header header-page">
    <div class="header-fixed">
      <nav
        class="navbar navbar-expand-lg header-nav scroll-sticky"
        :class="{ 'add-header-bg': isSticky }"
      >
        <div class="container">
          <div class="navbar-header">
            <a
              id="mobile_btn"
              href="javascript:void(0);"
              @click="toggleSidebar"
            >
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <router-link to="/home/" class="navbar-brand logo">
              <img
                src="@/assets/img/logo/logo_cabecera.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link>
          </div>

          <ul class="main-nav">
            <main-nav></main-nav>
            <router-link to="/" class="Login" v-if="!auth.user">
                <i class="fas fa-user mx-2"></i>
                <span>Iniciar Sesion</span>
              </router-link>
          </ul>
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import { useAuth } from "@/stores"; // Importar el store de autenticación

export default {
  data() {
    return {
      isSticky: false,
      isSidebarOpen: true,
      auth: useAuth(), // Instancia del store de autenticación

    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
<style>
  .Login{
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #f7f1ee;
    line-height: 70px;
        padding: 0 !important;
  }
</style>